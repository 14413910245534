<template>
  <el-card class="administrator-menu page-container">
    <!-- <SearchBar  slot="header" /> -->
    <el-form slot="header" :inline="true" size="small">
      <el-form-item label="菜单名称">
        <el-input></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="handleAddMenu('')">搜索</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="primary" @click="handleAddMenu({ parent_id: '' })">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="dragTable" height="100" :data="serverMenuInfo" row-key="id" :cell-class-name="setCellClassName">
      <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
      <el-table-column prop="id" label="编号" width="160" align="center">
        <template slot="header">
          <el-tooltip class="item" effect="dark" content="点击进入查看子菜单（蓝色可点击）灰色会跳转到父级菜单" placement="top-start">
            <span><i class="el-icon-question"></i> 编号 </span>
          </el-tooltip>
        </template>

        <template slot-scope="{ row }">
          <el-link :type="row.lists && row.lists.length ? 'primary' : 'info'" @click="enterInitMenuInfo(row)" :disabled="!Boolean(row.lists && row.lists.length)">{{ row.id }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="100" align="center">
        <template slot-scope="{ row }">
          <el-input v-model="row.name" size="mini" v-if="row.isEdit"></el-input>
          <span v-else>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="图标" class="icon-area" :show-overflow-tooltip="true">
        <template slot-scope="{ row }">
          <el-select v-model="row.icon" popper-class="grid-container" value-key="icon" size="mini" v-if="row.isEdit">
            <el-option :value="icon" :label="icon" v-for="icon in icons" :key="icon">
              <i :class="['iconfont', icon]" />
            </el-option>
          </el-select>
          <div class="show-icon-area" v-else>
            <i :class="['iconfont', row.icon]"></i>
            <span>{{ row.icon }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="uri" label="路径">
        <template slot-scope="{ row }">
          <el-input v-model="row.uri" size="mini" v-if="row.isEdit"></el-input>
          <span v-else>{{ row.uri }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="{ row }">
          {{ row.createTime ? $moment(row.createTime).format('YYYY-MM-DD') : '--:--' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="280">
        <template slot-scope="{ row }">
          <el-button type="text" icon="el-icon-edit" @click="row.isEdit = !row.isEdit">修改基础信息</el-button>
          <!-- <el-button type="text" icon="el-icon-circle-close" style="color: #e6a23c">禁用</el-button> -->
          <el-button type="text" icon="el-icon-rank">调整排序</el-button>
          <el-row v-if="row.isEdit">
            <el-button type="text" icon="el-icon-check" @click="handleChangeMenuInfo(row)">确定</el-button>
            <el-button type="text" icon="el-icon-close" @click="row.isEdit = false" style="color: #f40">取消</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- <PaginationBar @change:pageNumber="getPageNumber" /> -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" title="新建菜单">
      <el-form ref="SetMenuInfoForm" label-position="left" label-width="80px" :rules="rules" :model="menuInfo">
        <el-row :gutter="20">
          <el-col :span="showMenuType ? 12 : 24">
            <el-form-item label="类型">
              <el-select v-model="menuInfo.menuType" style="width: 100%">
                <el-option v-for="type in menuTypes" :key="type.value" :label="type.label" :value="type.value" :disabled="type.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="showMenuType">
            <el-form-item label="目录" prop="parentMenuId">
              <el-select v-model="menuInfo.parentMenuId" style="width: 100%">
                <el-option v-for="preMenu in serverMenuInfo" :key="preMenu.id" :label="preMenu.name" :value="preMenu.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="menuName">
              <el-input v-model="menuInfo.menuName" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路径" prop="menuUrl">
              <el-input v-model="menuInfo.menuUrl" placeholder="请输入菜单路径"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="图标" prop="menuIcon">
          <el-select v-model="menuInfo.menuIcon" popper-class="grid-container" value-key="icon" style="width: 100%" placeholder="请选择菜单图标">
            <el-option :value="icon" :label="icon" v-for="icon in icons" :key="icon">
              <i :class="['iconfont', icon]" />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button size="small" type="primary" @click="submit('SetMenuInfoForm')">确认</el-button>
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import SearchBar from './SearchBar.vue';
import PaginationBar from '@/components/Pagination';
import vuedraggable from 'vuedraggable';
import { getMenuInforList, updateMenuInfo } from '@/api/user';
import { updateMenuSort, insertMenuInfo } from '@/api/role.js';
import statusInclude from '@/utils/statusCode';
import Sortable from 'sortablejs';
import { icons } from '@/mock/icon.js';
export default {
  name: 'AdministratorMenu',
  components: {
    SearchBar,
    vuedraggable,
    PaginationBar,
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      icons,
      parentMenuId: '',
      menuTypes: [
        {
          value: 1,
          label: '目录',
        },
        {
          value: 2,
          label: '菜单',
        },
        // {
        //   value: 3,
        //   hidden: true,
        //   label: '权限模块',
        //   disabled: true,
        // },
      ],
      serverMenuInfo: [],
      oldList: [],
      newList: [],
      menuInfo: {
        parentMenuId: '',
        menuName: '',
        menuType: 1,
        menuUrl: '',
        menuIcon: '',
      },
      rules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' },
        ],
        menuUrl: [{ required: true, message: '请输入菜单路径', trigger: 'blur' }],
        menuIcon: [{ required: true, message: '请选择菜单图标', trigger: 'change' }],
        parentMenuId: [{ required: true, message: '请选择上级菜单', trigger: 'change' }],
      },
    };
  },

  computed: {
    showMenuType() {
      return this.menuInfo.menuType !== 1;
    },
    MenupreMenuParentId_Level() {
      return this.$route.path.split(':')[1].split('-');
    },
  },

  created() {
    this.getMenuInforList({ page: 1, size: 100, term: this.MenupreMenuParentId_Level });
  },
  mounted() {
    this.setSort();
  },
  methods: {
    setCellClassName({ columnIndex }) {
      if (columnIndex === 3) return 'icon-area';
    },

    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '');
        },
        onEnd: this.getSortMenuInfoParams,
      });
    },

    getSortMenuInfoParams(event) {
      const { oldIndex, newIndex } = event;
      const targetRow = this.serverMenuInfo.splice(oldIndex, 1)[0];
      this.serverMenuInfo.splice(newIndex, 0, targetRow);
      const currentMenu = this.newList.splice(oldIndex, 1)[0];
      this.newList.splice(newIndex, 0, currentMenu);
      const before = this.newList[newIndex - 1];
      const current = this.newList[newIndex];
      const params = this.fit({ before, current });
      this.updateMenuSort(params).then((response) => {
        if (!statusInclude(response.code)) return this.$message.error(response.reason);
        this.$message.success(response.reason);
        this.getMenuInforList({ page: 1, size: 100 });
      });
    },

    fit({ before = null, current = null }) {
      const params = {};
      const { id, sort, level } = current;
      Object.assign(params, { preMenuId: before?.id ? before?.id : '' });
      Object.assign(params, { preMenuParentId: before?.parentMenuId ? before?.parentMenuId : '' });
      Object.assign(params, { preMenusort: before?.sort ? before?.sort : '' });
      Object.assign(params, { preMenuLevel: before?.level ? before?.level : '' });
      Object.assign(params, { currentMenuId: id });
      Object.assign(params, { currentMenusort: sort });
      Object.assign(params, { currentMenuLevel: level });
      return params;
    },

    foo(tree, index) {
      const { childMens, createTime, createUserName, menuIcon, menuId, menuLevel, menuName, menuUrl, parentMenuId, sort } = tree;
      const node = {};
      node.index = index; //修正首次加载的排序保持与后端返回的一致
      node.id = menuId;
      node.sort = sort;
      node.level = menuLevel;
      node.parent_id = parentMenuId;
      node.parentMenuId = parentMenuId;
      node.name = menuName;
      node.uri = menuUrl;
      node.open = false;
      node.icon = menuIcon;
      node.createTime = createTime;
      node.createUserName = createUserName;
      node.lists = childMens;
      node.isEdit = false;
      return node;
    },

    transformTreeSource(trees) {
      const res = [];
      trees.forEach((tree, index) => {
        const tmp = this.foo(tree, index + 1);
        if (tmp.lists) {
          tmp.lists = this.transformTreeSource(tmp.lists);
        }
        res.push(tmp);
      });
      return res;
    },

    handleAddMenu({ parent_id: parentMenuId }) {
      if (parentMenuId) {
        this.parentMenuId = parentMenuId;
        this.menuInfo.parentMenuId = parentMenuId;
      }
      this.dialogVisible = true;
    },

    //修改菜单基本信息
    handleChangeMenuInfo(menuInfo) {
      const { uri, id, name, icon } = menuInfo;
      this.updateMenuInfo({ menuId: id, menuName: name, menuIcon: icon, menuUrl: uri }).then((code) => {
        if (!statusInclude(code)) return;
        menuInfo.isEdit = false;
      });
    },

    // 提交菜单信息
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.insertMenuInfo(this.menuInfo);
        } else {
          return false;
        }
      });
    },

    enterInitMenuInfo(preMenu) {
      this.$router.push({ path: preMenu.level === 1 && preMenu.lists?.length ? `:${preMenu.parentMenuId}-${preMenu.level}` : `:Id-level` });
    },

    // 新增菜单
    insertMenuInfo({ parentMenuId = '', menuName = '', menuUrl = '', menuIcon = '', menuType = 1 }) {
      insertMenuInfo({ parentMenuId: menuType == 1 ? '' : parentMenuId, menuName, menuUrl, menuIcon })
        .then((response) => {
          if (!statusInclude(response.code)) return this.$message.error(response.reason);
          this.$message.success(response.reason);
          this.dialogVisible = false;
        })
        .catch(this.$message.error);
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //删除菜单
    async handleRemove(row) {
      const { menuId, menuName, menuIcon, menuUrl } = row;
      const code = await this.updateMenuInfo({ menuId, menuName, menuIcon, menuUrl, isValid: 0 });
      if (!statusInclude(code)) return this.$message.error(`菜单删除失败：${reason}`);
      this.$message.success('删除成功');
      this.getMenuInforList({ page: 1, size: 100 });
    },

    async ch1angeMenuStatus(row) {
      const { menuId, menuName, menuIcon, menuUrl, isValid } = row;
      const code = await this.updateMenuInfo({ menuId, menuName, menuIcon, menuUrl, isValid: !isValid ? 1 : 0 });
      if (statusInclude(code)) {
        row.isValid = row.isValid ? 0 : 1;
      }
    },

    async updateMenuInfo({ menuId, menuName, menuIcon, menuUrl }) {
      const { code, reason, data } = await updateMenuInfo({ menuId, menuName, menuIcon, menuUrl });
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '设置成功' });
      } else {
        this.$message.error(`请求失败：${reason}`);
      }
      return code;
    },

    //请求菜单信息
    async getMenuInforList({ page: pageIndex, size: pageSize, name: menuName = '', term }) {
      const [preMenuId, level] = term;
      const { code, data, reason } = await getMenuInforList({ pageIndex, pageSize, menuName });
      if (!statusInclude(code)) return this.$message({ type: 'success', message: reason });
      this.total = data.total;
      this.serverMenuInfo = this.transformTreeSource(data.records);
      if (preMenuId && level == 1) {
        this.menuInfo.menuType = 2;
        this.menuInfo.parentMenuId = preMenuId;
        try {
          this.serverMenuInfo = this.serverMenuInfo.find((menu) => menu.id === preMenuId).lists;
        } catch (error) {
          console.log(error);
        }
      }
      this.oldList = this.serverMenuInfo.slice();
      this.newList = this.oldList.slice();
    },

    async updateMenuSort(params) {
      return await updateMenuSort(params);
    },
  },
};
</script>

<style lang="scss" scoped>
// .administrator-menu-wrapper {
//   --main-menu-icon-size: 20px;
//   height: calc(100% - 42px);
//   /deep/ .el-card {
//     height: 100%;
//     .el-card__body {
//       height: calc(100% - 71px);
//       display: flex;
//       flex-direction: column;
//       .el-table {
//         flex: 1;
//         .icon-area {
//           .cell {
//             .show-icon-area {
//               height: 100%;
//               display: flex;
//               flex-direction: row;
//               align-items: center;
//               i {
//                 font-size: var(--main-menu-icon-size);
//                 margin-right: 10px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>

<style></style>

<style lang="scss">
.sortable-ghost {
  td {
    opacity: 0.8;
    color: #fff !important;
    background: #42b983 !important;
  }
}

.grid-container {
  background: #ff4400;
  --span-size: 100px;
  --gap-size: calc(var(--span-size) / 14);
  --column-gap-size: var(--gap-size);
  --row-gap-size: calc(var(--gap-size) / 4 * 5);
  --icon-font-size: 16px;
  .el-scrollbar {
    .el-select-dropdown__wrap {
      ul.el-select-dropdown__list {
        display: grid !important;
        place-items: center !important;
        grid-template-columns: repeat(14, 4fr) !important;
        column-gap: var(--column-gap-size) !important;
        row-gap: var(--row-gap-size) !important;
        .el-select-dropdown__item {
          i {
            font-size: var(--icon-font-size);
          }
        }
      }
    }
  }
}
</style>
