<template>
  <div class="search-bar-wrap">
    <el-form :inline="true" size="small">
      <el-form-item></el-form-item>
      <el-form-item>
        <el-button type="success">success</el-button>
        <el-button type="warning">warning</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.el-button {
  --button-plan-background: rgba(54, 197, 73, 0.2);
  --success-plan: rgba(54, 197, 73, 0.2);
  --warning-plan: rgba(235, 181, 99, 0.2);
  --info-plan: rgba(235, 181, 99, 0.2);
  --danger-plan: rgba(235, 181, 99, 0.2);

  --success: green;
  --warning: #ebb563;
  --info: rgb(107, 107, 126);
  --danger: red;
  &--success {
    --button-plan-background: var(--success-plan);
  }
  &--warning {
    --button-plan-background: var(--warning-plan);
    border-color: var(--warning-plan);
    color: #fff;
    &:hover {
      --button-plan-background: var(--warning);
      border-color: var(--warning-plan);
    }
    &:active {
      --button-plan-background: var(--warning);
      border-color: var(--warning-plan);
    }
    &:focus {
      --button-plan-background: var(--warning);
      border-color: var(--warning-plan);
    }
  }

  background: var(--button-plan-background) !important;
}
</style>
