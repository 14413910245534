export const icons = [
  'icon-jurassic_process-sent-',
  'icon-shipinguangchang',
  'icon-shipinguangchang1',
  'icon-jiugongge',
  'icon-buju',
  'icon-sigongge_ying',
  'icon-menu_kongzhitai',
  'icon-gongdan',
  'icon-zhandianguanli',
  'icon-a-yewuguanliqiyeguanli',
  'icon-hangxianguanli-01',
  'icon-xiangmuzuguanli',
  'icon-jizhan',
  'icon-jizhan1',
  'icon-qiyeyonghu',
  'icon-cloud-machine',
  'icon-shepin_fill',
  'icon-shepinguanlimeirongjihua',
  'icon-xiazai41',
  'icon-yiduxiaoxi',
  'icon-wulumuqishigongandashujuguanlipingtai-ico-',
  'icon-svg-',
  'icon-weiduxinxi',
  'icon-guanliyuan',
  'icon-quanbuxiaoxi',
  'icon-shenpi',
  'icon-zuzhi',
  'icon-remote-control',
  'icon-bangzhuwendang',
  'icon-bangzhuwendang1',
  'icon-kongzhizhongxin',
  'icon-tuichu',
  'icon-shujuzhongxin',
  'icon-jihuarenwu',
  'icon-jiaoseguanli',
  'icon-yonghuguanli',
  'icon-caidanguanli',
  'icon-qrcode',
  'icon-QRcode',
  'icon-qiehuan',
  'icon-kuangxuan1',
  'icon-wurenji',
  'icon-fengsu',
  'icon-quanpingsuoxiao',
  'icon-quanpingxianshi',
  'icon-stop-circle',
  'icon-luxiang01',
  'icon-ziyuan',
  'icon-24gf-record',
  'icon-24gf-recordCircle',
  'icon-layer',
  'icon-paizhao',
  'icon-suoding',
  'icon-jiting',
  'icon-shuaxin',
  'icon-quanping',
  'icon-quanping1',
  'icon-shuaxin1',
  'icon-daoru',
  'icon-baocun',
  'icon-jiaohurizhi',
  'icon-xitongrizhi',
  'icon-rizhiguanli',
  'icon-caozuorizhi',
  'icon-wind-speed',
  'icon-fengxiang',
  'icon-fenxi',
  'icon-tiaoduguanli',
  'icon-shuzhuangtu',
  'icon-shujutongji',
  'icon-guanbi',
  'icon-shanchutianchong',
  'icon-bianji',
  'icon-daochu',
  'icon-fuzhi-copy',
  'icon-triangle-bottom',
  'icon-triangle-right',
  'icon-triangle-left',
  'icon-triangle-top',
  'icon-box',
  'icon-renwujincheng',
  'icon-xinzeng',
  'icon-012-zhandian',
  'icon-renwu',
  'icon-shepinxiaorongRFA',
  'icon-dianchi',
  'icon-hangpai',
  'icon-shouye',
  'icon-ico_shebeiguanli_shebeipeizhijingzhunyixue',
  'icon-xianluguihua',
  'icon-yonghu',
  'icon-yonghuguanli-yonghuguanli',
  'icon-fcbox',
]